import { FormState } from '../../context/registerContext/registerContext.type'
import { UserUpgrade } from './useAccountUpgrade.type'

export function prepareUserForAPI(formState: FormState): UserUpgrade {
   return {
      Email: formState.email,
      FirstName: formState.firstName,
      LastName: formState.lastName,
      Company: formState.company,
      AcceptTerms: 'true',
      City: formState.city,
      State: formState.stateProvince,
      PhoneNumber: formState.phoneNumber,
      Country: formState.country,
   }
}
