import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import { RegisterFlow } from '../../../components/views/layout'
import { Alert, LoadingOverlay, Seo } from '../../../components/common'
import { RegisterForm } from '../../../components/common/register'
import { useRegister, useAccountUpgrade, useProfile } from '../../../hooks'
import { prepareUserForAPI } from '../../../hooks/useAccountUpgrade/useAccountUpgrade.helpers'
import { isEmptyObject } from '../../../common/utils/functions'
import { Paths } from '../../../constants/structure'

import { RegisterData } from '../../../components/common/register/registerForm/registerForm.type'

import { registerFields } from '../../../common/utils/register'

const UpgradePage = () => {
   const { formState, update } = useRegister()
   const { error, isLoading, isSuccess, upgrade } = useAccountUpgrade()
   const { refresh: refreshProfile } = useProfile()

   const isInvalid =
      isEmptyObject(formState) || !formState[registerFields.EMAIL]

   useEffect(() => {
      if (isInvalid) {
         navigate(Paths.REGISTER)
      }
   }, [])

   useEffect(() => {
      if (isSuccess) {
         // refresh the user profile to ensure the role is upgraded and avoid unauthorised message when redirecting
         refreshProfile()
         handleSuccessfulUpgrade()
      }
   }, [isSuccess])

   const handleFormSubmit = (data: RegisterData) => {
      const newFormState = { ...formState, ...data }

      update(newFormState)
      upgrade(prepareUserForAPI(newFormState))
   }

   const handleSuccessfulUpgrade = () =>
      navigate(Paths.REGISTER_UPGRADE_CONFIRMATION)

   return (
      <RegisterFlow title="Step 2">
         <Seo title="Register - Step 2" />

         {!!error && <Alert message={error} />}

         {isInvalid ? (
            <LoadingOverlay />
         ) : (
            <RegisterForm
               data={formState}
               isLoading={isLoading}
               onRegister={handleFormSubmit}
               showPasswordFields={false}
               submitLabel="Upgrade"
            />
         )}
      </RegisterFlow>
   )
}

export default UpgradePage
